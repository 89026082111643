import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useGetLogsQuery } from "state/api";
import Header from "components/Header";
// import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Link from '@mui/material/Link';

const Logs = () => {
  const theme = useTheme();

  // values to be sent to the backend
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(20);
  // const [sort, setSort] = useState({});
  // const [search, setSearch] = useState("");

  // const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetLogsQuery();
  console.log("Logs ~ data:", data)

  function ExpandableCell({ value }) {
    const [expanded, setExpanded] = React.useState(false);
  
    return (
      <div>
        {expanded ? value : value.slice(0, 200)}&nbsp;
        {value.length > 200 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            type="button"
            component="button"
            sx={{ fontSize: 'inherit' }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'view less' : 'view more'}
          </Link>
        )}
      </div>
    );
  }

  const columns = [
    {
      field: "user",
      headerName: "User",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Timestamp",
      flex: 1.2,
      valueFormatter: (params) => {
        if (params.value === null || params.value === undefined) {
          return '';
        }
        const date = new Date(params.value);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        });
      },
    },
    {
      field: "request",
      headerName: "Prompt",
      flex: 2,
    },
    {
      field: "functionName",
      headerName: "Function Name",
      flex: 1,
    },
    {
      field: "llm",
      headerName: "LLM",
      flex: 1,
    },
    {
      field: "inputToken",
      headerName: "Tokens",
      flex: .5,
    },
    {
      field: "result",
      headerName: "result",
      flex: 4,
      renderCell: (params) => <ExpandableCell {...params} />,
    },
    {
      field: "errorMessage",
      headerName: "Error",
      flex: .5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: .7,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Logs" subtitle="Logs of Every LLM Call" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          getEstimatedRowHeight={() => 100}
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
{/*         <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.logs) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        /> */}
      </Box>
    </Box>
  );
};

export default Logs;
