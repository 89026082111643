import React, { useState, useEffect, useRef } from "react";
import { 
  Box, 
  TextField, 
  Button, 
  Typography,
  IconButton,
  Link,
  useTheme,
  Snackbar,
  Alert
} from '@mui/material';
import { 
  Send as SendIcon, 
  ThumbUp as ThumbUpIcon, 
  ThumbDown as ThumbDownIcon, 
  Download as DownloadIcon 
} from '@mui/icons-material';
import Header from "components/Header";
import { useSelector } from "react-redux";
import "./Chat.css";

const Chat = () => {
  const theme = useTheme();
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  var user = useSelector((state) => state.global.user);
  const name = user?.name;
  const ID = user?.id;
  const names = user?.name?.split(' ') ?? [];
  const initials = names.map(name => name.charAt(0).toUpperCase());
  const containerRef = useRef(null);
  const shouldScrollRef = useRef(true);

  const renderMessageWithLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Split the text into parts (URLs and non-URLs)
    const parts = text.split(urlRegex);
    const matches = text.match(urlRegex) || [];
    
    return parts.map((part, index) => {
      // If the part matches a URL, render it as a link
      if (matches.includes(part)) {
        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              wordBreak: 'break-all',
              color: 'inherit',
              textDecoration: 'underline',
              '&:hover': {
                opacity: 0.8
              }
            }}
          >
            {part}
          </Link>
        );
      }
      // Otherwise render it as regular text
      return <span key={index}>{part}</span>;
    });
  };

  const chat = async (e) => {
    e.preventDefault();

    const message = input.trim();

    if (!message) return;
    shouldScrollRef.current = true;
    setIsTyping(true);
    setInput('');

    let msgs = chats;
    msgs.push({ role: "user", content: message, metadata: { createdAt: new Date() } });
    setChats(msgs);

    setMessage("");

    fetch(process.env.REACT_APP_CHAT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chats,
        name,
        ID,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        msgs.push(data);
        setChats(msgs);
        setIsTyping(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // }, [chats]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  useEffect(() => {
    scrollToBottom();
  }, [isTyping]);

  const scrollToBottom = () => {
    if (containerRef.current && shouldScrollRef.current) {
      const scrollElement = containerRef.current;
      const scrollHeight = scrollElement.scrollHeight;
      const height = scrollElement.clientHeight;
      const maxScrollTop = scrollHeight - height;
      
      scrollElement.scrollTo({
        top: maxScrollTop,
        behavior: 'smooth',
      });
    }
  };

  const handleDownload = () => {
    // Format the chat log with proper timestamps, roles, and content
    const formatDate = (date) => {
      return new Date(date).toLocaleString();
    };

    const content = chats.map(message => {
      // const id = message.metadata?.logId || 'N/A';
      const timestamp = formatDate(message.metadata?.createdAt || new Date());
      const sender = message.role === 'user' ? initials : message.metadata?.tool || 'AI Agent';
      const messageContent = message.content || '';
      
      return `[${timestamp}] ${sender}: ${messageContent}`;
    }).join('\n\n');

    // Add a header to the file
    const header = 'Chat History\n' +
                  '=============\n' +
                  `Downloaded on: ${formatDate(new Date())}\n\n`;

    const fullContent = header + content;

    // Create and download the file
    const blob = new Blob([fullContent], { 
      type: 'text/plain;charset=utf-8' 
    });
    
    // Create a formatted filename with date
    const fileName = `chat-history-${new Date().toISOString().split('T')[0]}.txt`;
    
    // Create download link and trigger download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    setSnackbar({
      open: true,
      message: 'Chat history downloaded successfully',
      severity: 'success'
    });
  };

  const handleRating = (index, rating) => {
    setChats(prev => prev.map((msg, i) => {
      if (i === index) {
        // If the same rating is clicked again, remove it
        const newRating = msg.rating === rating ? null : rating;
        return { ...msg, rating: newRating };
      }
      return msg;
    }));

    // send the rating to your backend
    console.log(`Message ${index} rated ${rating}`);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CHAT" subtitle="Chat with the Agent" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .user_msg": {
            backgroundColor: `${theme.palette.secondary[800]} !important`,
            padding: "15px",
            borderRadius: "50px",
            marginLeft: "auto",
            maxWidth: "80%",
            marginBottom: "1rem",
          },
          "& .agent_msg": {
            backgroundColor: `${theme.palette.primary.light} !important`,
            padding: "15px",
            borderRadius: "50px",
            maxWidth: "80%",
            marginBottom: "1rem",
          },
          display: 'flex',
          flexDirection: "row",
          whiteSpace: "pre-wrap",
        }}
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Box
            ref={containerRef}
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              mb: 2,
              p: 2,
              height: 'calc(80vh - 140px)', // Account for input box and margins
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: theme.palette.background.alt
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.secondary[200]
              }
            }}
          >
            {chats.map((chat, index) => (
              <Box
                key={index}
                className={chat.role === "user" ? "user_msg" : "agent_msg"}
              >
                <Typography component="span" fontWeight="bold">
                  {chat.role === "user" ? initials : chat.metadata.tool}
                </Typography>
                <Typography component="span">: </Typography>
                <Typography component="span">
                  {renderMessageWithLinks(chat.content)}
                </Typography>
                {chat.role === "assistant" && (
                  <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end', gap: 1  }}>
                    <IconButton 
                      size="small"
                      onClick={() => handleRating(index, 'up')}
                      sx={{ 
                        color: chat.rating === 'up' 
                          ? theme.palette.primary[500] 
                          : theme.palette.secondary[100],
                        transition: 'color 0.2s',
                        '&:hover': {
                          color: theme.palette.secondary[300]
                        }
                      }}
                    >
                      <ThumbUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      size="small"
                      onClick={() => handleRating(index, 'down')}
                      sx={{ 
                        color: chat.rating === 'down' 
                          ? theme.palette.error.main 
                          : theme.palette.secondary[100],
                        transition: 'color 0.2s',
                        '&:hover': {
                          color: theme.palette.error.main
                        }
                      }}
                    >
                      <ThumbDownIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
            {isTyping && (
              <Typography variant="body2" sx={{ fontStyle: 'italic', ml: 2 }}>
                Typing...
              </Typography>
            )}
          </Box>
          
          <Box sx={{ p: 2, backgroundColor: theme.palette.background.alt }}>
            <form onSubmit={chat} style={{ display: 'flex', gap: '1rem' }}>
              <TextField
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message here and hit Enter..."
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.secondary[100],
                    '& fieldset': {
                      borderColor: theme.palette.secondary[200],
                    },
                  },
                }}
              />
              <Button 
                type="submit"
                variant="contained"
                sx={{ backgroundColor: theme.palette.secondary[300] }}
              >
                <SendIcon />
              </Button>
              <Button 
                onClick={handleDownload}
                variant="outlined"
                sx={{ 
                  borderColor: theme.palette.secondary[200],
                  color: theme.palette.secondary[100]
                }}
              >
                <DownloadIcon />
              </Button>
            </form>
          </Box>
        </Box>
        <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: snackbar.severity === 'success' 
              ? theme.palette.secondary[300]
              : theme.palette.error.main,
            color: 'white'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box width="20%">
        </Box>
      </Box>
      <Box>

      </Box>
    </Box>
  )
}

export default Chat