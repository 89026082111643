import React from "react";
import { Box, useTheme } from "@mui/material";
import { useGetToolUserQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import axios from "axios";

const Admin = () => {
  const theme = useTheme();
  var { data, isLoading } = useGetToolUserQuery();
  console.log("Admin ~ data:", data)

  const handleRemoveRow = async (rowId) => {
    try {
      console.log('Removing row:', rowId);
      await axios.delete(`http://localhost:8000/api/rows/${rowId}`);
    } catch (error) {
      console.error('Error removing row:', error);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "tool",
      headerName: "Tool",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      width: 700,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <button onClick={() => handleRemoveRow(params.row._id)}>Remove</button>
      ),
    }
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ADMIN" subtitle="Managing users access to tools" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[500]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />
      </Box>
    </Box>
  );
};

export default Admin;
