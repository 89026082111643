import React from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import signinImage from "assets/ms-symbollockup_signin_light.svg";
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { Box, } from "@mui/material";

const Signin = () => {
    const { instance } = useMsal();

    const handleLogin = async () => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }

      instance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          instance.setActiveAccount(account);
        }
      }, error=>{
        console.log('error', error);
      });

      console.log('get active account', instance.getActiveAccount());

      // handle auth redired/do all initial setup for msal
      instance.handleRedirectPromise().then(authResult=>{
        // Check if user signed in 
        const account = instance.getActiveAccount();
        if(!account){
          // redirect anonymous user to login page 
          instance.loginRedirect();
        }
      }).catch(err=>{
        // TODO: Handle errors
        console.log(err);
      });
    };

    return (
        <Box m="1.5rem 2.5rem">
          <FlexBetween>
            <Header title="Sign in using Microsoft" />
            <Box>
              <img src={signinImage} alt="Login" onClick={handleLogin}  />
            </Box>
          </FlexBetween>
          </Box>
  );
};

export default Signin;