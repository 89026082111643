import { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { setUser } from "state/index";
import { useDispatch } from 'react-redux';

function AuthRedirectHandler() {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isRedirectHandled, setIsRedirectHandled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isRedirectHandled && inProgress === InteractionStatus.None) {
      instance.handleRedirectPromise()
        .then((response) => {
          if (response) {
            // If we have a response, it means we've just been redirected from Azure AD
            console.log("Successful redirect response");
            acquireTokenAndCallBackend();
          } else if (isAuthenticated && accounts.length > 0) {
            // We're already authenticated but not due to a redirect (e.g., page refresh)
            acquireTokenAndCallBackend();
          }
        })
        .catch((error) => {
          console.error("Error handling redirect:", error);
        })
        .finally(() => {
          setIsRedirectHandled(true);
        });
    }
  }, [instance, accounts, inProgress, isAuthenticated, isRedirectHandled]);

  const acquireTokenAndCallBackend = () => {
    const account = accounts[0] || null;
    const tokenRequest = {
        scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/access_as_user`],
      account: account
    };

    instance.acquireTokenSilent(tokenRequest)
      .then((response) => {
        //console.log("Token acquired successfully", response.accessToken);
        callBackend(response.accessToken);
      })
      .catch((error) => {
        console.error("Error acquiring token:", error);
        // You might want to handle specific errors here, e.g., refresh the token if it's expired
      });
  };

  const callBackend = (token) => {
    fetch(process.env.REACT_APP_BASE_URL + '/user/auth', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ /* any data you want to send */ })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Backend call successful:');
      // Handle the response from your backend
      // Dispatch the setUser action with the user data
      dispatch(setUser({
        userId: data.userId, // or userData.homeAccountId, depending on your backend response
        id: data.userId, // or userData.homeAccountId, depending on your backend response
        name: data.name,
        email: data.email,
        role: data.role
      }));

      console.log('User data stored in Redux');

    })
    .catch((error) => {
      console.error('Error calling backend:', error);
    });
  };

  return null; // This component doesn't render anything
}

export default AuthRedirectHandler;