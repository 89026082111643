import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Button,
  Switch,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  CircularProgress
} from '@mui/material';
import { useGetToolsForUserQuery, useUpdateToolsForUserMutation } from 'state/api'; // Adjust the import path as needed

const ToolAccessDialog = ({ open, onClose, userId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTools, setSelectedTools] = useState([]);

  const { data: toolUserData, isLoading, isError } = useGetToolsForUserQuery(userId);
  const [updateToolUser] = useUpdateToolsForUserMutation();

  useEffect(() => {
    if (toolUserData) {
      setSelectedTools(toolUserData.accessibleTools);
    }
  }, [toolUserData]);

  const handleToolToggle = (toolId) => {
    setSelectedTools(prev => 
      prev.includes(toolId) 
        ? prev.filter(id => id !== toolId)
        : [...prev, toolId]
    );
  };

  const handleSave = async () => {
    try {
      await updateToolUser({ userId, toolIds: selectedTools }).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to update tool access:', error);
      // Handle error (e.g., show an error message)
    }
  };

  const filteredTools = toolUserData?.tools.filter(tool => 
    tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tool.description.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography color="error">Error loading tools. Please try again.</Typography>;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Tool Access</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Search tools"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <List sx={{ maxHeight: 400, overflow: 'auto' }}>
          {filteredTools.map(tool => (
            <ListItem key={tool._id} divider>
              <ListItemText
                primary={tool.name}
                secondary={tool.description}
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={selectedTools.includes(tool._id)}
                  onChange={() => handleToolToggle(tool._id)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {filteredTools.length === 0 && (
          <Typography color="textSecondary" align="center" sx={{ mt: 2 }}>
            No tools found matching your search.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToolAccessDialog;