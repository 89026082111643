import React from "react";
import { Box, useTheme, Button } from "@mui/material";
import { useGetToolInfoQuery, useAddToolMutation, useDeleteToolsMutation, useUpdateToolMutation } from "state/api";
import Header from "components/Header";
//import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';
import FlexBetween from "components/FlexBetween";
import { DownloadOutlined } from "@mui/icons-material";
import ToolManagementGrid from "components/ToolManagementGrid";

const Tools = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetToolInfoQuery();
  const [addTool] = useAddToolMutation();
  const [updateTool] = useUpdateToolMutation();
  const [deleteTools] = useDeleteToolsMutation();

  const handleAddTool = async (newTool) => {
    try {
      await addTool(newTool).unwrap();
      // Optionally refetch or update local state
    } catch (error) {
      console.error('Failed to add tool:', error);
    }
  };

  const handleUpdateTool = async (updatedTool) => {
    try {
      console.log('updatedTool:', updatedTool);
      await updateTool({id: updatedTool._id, toolData: updatedTool}).unwrap();
      // Optionally refetch or update local state
    } catch (error) {
      console.error('Failed to update tool:', error);
    }
  };

  const handleDeleteTools = async (toolIds) => {
    try {
      await deleteTools(toolIds).unwrap();
      // Optionally refetch or update local state
    } catch (error) {
      console.error('Failed to delete tools:', error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
        <FlexBetween>
       <Header title="Tools" subtitle="Track your Tools"/>
       <Box>
           <Button
             sx={{
               backgroundColor: theme.palette.secondary.light,
               color: theme.palette.background.alt,
               fontSize: "14px",
              fontWeight: "bold",
               padding: "10px 20px",
             }}
           >
             <DownloadOutlined sx={{ mr: "10px" }} />
             Download Reports
           </Button>
         </Box>
         </FlexBetween>
    <ToolManagementGrid
      data={data}
      isLoading={isLoading}
      onAddTool={handleAddTool}
      onUpdateTool={handleUpdateTool}
      onDeleteTools={handleDeleteTools}
    />
    </Box>
  );
 };

export default Tools;
