import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Users",
    "Dashboard",
    "Logs",
    "ToolUsage",
    "ToolUsers",
    "ToolInfo",
    "Role",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `user/user/${id}`,
      providesTags: ["User"],
    }),
    getRole: build.query({
      query: (id) => `user/role/${id}`,
      providesTags: ["Role"],
    }),
    getUsers: build.query({
      query: () => "user/users",
      providesTags: ["Users"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    getLogs: build.query({
      query: () => "logs/logs",
      providesTags: ["Logs"],
    }),
    getToolUsage: build.query({
      query: () => "logs/toolusage",
      providesTags: ["ToolUsage"],
    }),
    getToolUser: build.query({
      query: () => "user/toolusers",
      providesTags: ["ToolUsers"],
    }),
    getToolsForUser: build.query({
      query: (userId) => `user/tooluser/${userId}`,
      providesTags: ['ToolUsers'],
    }),
    updateToolsForUser: build.mutation({
      query: ({ userId, toolIds }) => ({
        url: `user/tooluser/${userId}`,
        method: 'PUT',
        body: { toolIds },
      }),
      invalidatesTags: ['ToolUsers'],
    }),
    getToolInfo: build.query({
      query: () => "tool/toolinfo",
      providesTags: ["ToolInfo"],
    }),
    updateRole: build.mutation({
      query: ({userId, newRole}) => ({
        url: `user/modrole/${userId}`,
        method: "PUT",
        body: {role: newRole},
      }),
      invalidatesTags: ["Role"],
    }),
    addTool :build.mutation({
      query: (data) => ({
        url: `tool/addtool`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ToolInfo"],
    }),
    updateTool :build.mutation({
      query: ({id, toolData}) => ({
        url: `tool/updatetool/${id}`,
        method: "PUT",
        body: {toolData: toolData},
      }),
      invalidatesTags: ["ToolInfo"],
    }),
    deleteTools :build.mutation({
      query: (data) => ({
        url: `tool/deletetool`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["ToolInfo"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetDashboardQuery,
  useGetLogsQuery,
  useGetToolUsageQuery,
  useGetToolUserQuery,
  useGetToolInfoQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useAddToolMutation,
  useUpdateToolMutation,
  useDeleteToolsMutation,
  useGetToolsForUserQuery,
  useUpdateToolsForUserMutation,
} = api;
