import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { useGetToolUsageQuery } from "state/api";

const OverviewChart = ({ isDashboard = false, view }) => {
  const theme = useTheme();
  const { data, isLoading } = useGetToolUsageQuery();

  const colors = [
    theme.palette.secondary[500],
    theme.palette.secondary[300],
    theme.palette.secondary[200],
    theme.palette.secondary[700],
    theme.palette.secondary[500],
    theme.palette.secondary[300],
    theme.palette.secondary[200],
    theme.palette.secondary[700],
    theme.palette.secondary[500],
    theme.palette.secondary[300],
    theme.palette.secondary[200],
    theme.palette.secondary[700],
  ];

  if (!data || isLoading) return "Loading...";

  const formattedData = Object.values(
    data.monthlyStats.reduce((result, item) => {
      const { functionName, month } = item._id;
      const { count } = item;
  
      const name = functionName !== '' ? functionName : 'GPT-4o';
      const randomIndex = Math.floor(Math.random() * colors.length);
      const randomColor = colors[randomIndex];
      const getMonthName = (monthNumber) => {
        const monthNames = {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };
      
        return monthNames[monthNumber];
      };
      const monthName = getMonthName(month.toString());
  
      if (!result[name]) {
        result[name] = {
          id: name,
          color: randomColor,
          data: [
            { x: 'March', y: 0 },
            { x: 'April', y: 0 },
            { x: 'May', y: 0 },
          ],
        };
      }

      const monthIndex = result[name].data.findIndex(
        (entry) => entry.x === monthName
      );
  
      if (monthIndex !== -1) {
        result[name].data[monthIndex] = { x: monthName, y: count };
      } else {
        result[name].data.push({ x: monthName, y: count });
      }
  
      return result;
    }, {})
  );

  console.log(formattedData);
  
  // data.monthlyStats.map(
  //   (item, i) => ({
  //     id: item._id.functionName === '' ? 'GPT-4' : item._id,
  //     data: 
  //     label: item._id === '' ? 'GPT-4' : item._id,
  //     value: item.count,
  //     color: colors[i],
  //   })
  // );


  return (
    <ResponsiveLine
      data={formattedData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: theme.palette.secondary[200],
            },
          },
          legend: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.secondary[200],
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.secondary[200],
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.secondary[200],
          },
        },
        tooltip: {
          container: {
            color: theme.palette.primary.main,
          },
        },
      }}
      margin={{ top: 20, right: 200, bottom: 50, left: 70 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      enableArea={!isDashboard}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: (v) => {
          if (isDashboard) return v.slice(0, 3);
          return v;
        },
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Month",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Calls',
        legendOffset: -60,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      colors={{ scheme: 'paired' }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        isDashboard
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : undefined
      }
    />
  );
};

export default OverviewChart;
