import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Button,
  Select,
  MenuItem,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useUpdateRoleMutation } from 'state/api';

const RoleDialog = ({ open, onClose, userId, currentRole }) => {
    const [newRole, setNewRole] = useState(currentRole);
    const [updateRole, {isLoading}] = useUpdateRoleMutation();
    const theme = useTheme();
  
    const handleChange = (event) => {
      setNewRole(event.target.value);
    };
  
    const handleSubmit = async () => {
      try {
        console.log(`Updating role for user ${userId} to ${newRole}`);
        await updateRole({ userId, newRole: newRole });
        onClose();
      } catch (error) {
        console.error('Failed to update role:', error);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.secondary[900], // Change the background color here
        },
      }}
      >
        <DialogTitle>Change User Role</DialogTitle>
        <DialogContent>
          <Select value={newRole} onChange={handleChange} fullWidth>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default RoleDialog;